import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import './char-guide.scss';
import { NikkeCharacter } from '../../../modules/nikke/common/components/nikke-character';
import { Alert, Col, Row } from 'react-bootstrap';
import ScrollspyNav from 'react-scrollspy-nav';
import { NikkeSkill } from '../../../modules/nikke/common/components/nikke-skill';

const NikkeGuidesRouge: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page char-guide'} game="nikke">
      <ul className="breadcrumb">
        <li>
          <Link to="/nikke/">NIKKE</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/nikke/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Rouge guide & review</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/nikke/categories/category_rouge.webp"
            alt="Guide"
          />
        </div>
        <div className="page-details">
          <h1>Rouge Guide & Review</h1>
          <h2>A guide & review for Rouge in NIKKE: Goddess of Victory.</h2>
          <p>
            Last updated: <strong>21/09/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <div className="guide-with-columns">
          <div className="left-content">
            <div className="section-scroll" id="section-0">
              <SectionHeader title="Rouge" />
              <div className="employee-container for-nikke">
                <NikkeCharacter mode="icon" slug="rouge" enablePopover />
              </div>
              <p>
                Immerse in the chanceful world of deception and intuition, where
                you can gamble all your riches away and have a shot at becoming
                the world's richest entrepreneur. Learn how to manipulate
                probability from Rouge, the erudite leader of 777 and a seasoned
                fortune hunter, and perhaps you will be the next lucky person to
                win a million dollars.
              </p>
              <h5>Can you give a brief summary about this unit?</h5>
              <p>
                Rouge is a stately support with CDR and a fantastic one at that,
                comparable to the well-known D:Wife. Unlike Liter or Volume, who
                both require stacking to achieve the smoothest rotations,
                Rouge's CDR is instantly at its peak value. This is mainly
                useful in Campaign and Raids, where those 3 to 6 seconds lost
                waiting in the first three rotations could have netted you
                another hundred million of damage. Her CDR is also easy to
                trigger, and since it is not tied to using Burst Skill, you can
                also use her as an off-burst CDR unit. The requirement is
                simple; you only have to full-charge 8 times before the next
                burst cycle begins. This is literally the same condition imposed
                on D:Wife, so ShiftUp is obviously not being creative here.
              </p>
              <p>
                Although CDR is Rouge's main selling point, she does offer
                plenty of other buffs on the table. As a game master, she likes
                to fiddle with coins, and her buff-providing utilities that only
                activate upon fulfilling a certain condition are related to
                these coins. When a coin activates, a continuous buff is
                provided to the team, and the effect of her Burst Skill is
                strengthened. Taking everything into account, Rouge can
                constantly increase the team's MAX HP (with or without restoring
                their HP), increase the Attack Damage of two units and reduce
                the damage they take, as well as boost the team's ATK every once
                in a while. The values of these buffs are not game-changing, but
                they are there and can be felt, especially since Rouge is the
                first CDR who can actually 'heal' the team.
              </p>
              <p>
                One of Rouge's other perks is that she can be deployed together
                with Blanc & Noir to reinforce them and perhaps even separate
                the once unbroken bond of the bunny duo. Ideal? No one knows
                yet, but it does unlock a few team ideas to experiment with. For
                example, Rouge + Blanc can employ 3 B3 DPS at the same time, and
                Blanc no longer has to succumb to Noir's slot-pressuring whims.
                What about the DPS? Well, Asuka + Rei + Modernia is a powerful
                trio DPS that can join them, but you can come up with other
                ideas on the fly, such as the Electric Trio of Ein + S.Anis +
                Scarlet. Whether this is effective will highly depend on your
                current state of investments and roster, and the same advice
                cannot be suggested to everybody.
              </p>
              <p>
                As for deployment, we think Rouge will be completing Alice's or
                RH's team as an instant CDR unit in Raids or any teams that lack
                CDR + survivability and need an all-in-one package solution.
                This is because their teams tend to rotate very quickly, thanks
                to RH's and Alice's excellent burst regen. Rouge + Blanc is also
                a feasible combo that eliminates Noir's slot-pressuring
                tendencies. She will definitely be replacing Volume in Tribe
                Tower and even sideline Noir as well if the use of SAnis is
                envisioned. When it comes to PvP, however, her presence is low
                due to the fact that her Burst Skill is horrendously weak at the
                first second and she needs time to properly ramp it up.
              </p>
              <h5>Should I pull this unit?</h5>
              <p>
                She is not exactly groundbreaking. You will not experience an
                OMG experience if you already have other meta CDR units, like
                Liter or D:Wife. If you do not have one, though, and have a lot
                of gems to spare, getting her will significantly improve your
                QoL in Campaign and damage in Raids. Besides that, if you
                somehow have Blanc but not Noir, Rouge will also allow you to
                use Blanc and finally cry tears of joy.
              </p>
              <p>
                Meanwhile, endgame players will benefit from having Rouge in
                Raids, as she supersedes Dorothy as the third best CDR unit in
                the game. Both Alice and RH require an instant CDR unit, and
                while Dorothy + Privaty would have been sufficient in the past,
                we usually always opt for the more superior options.
                Specifically in Solo Raid, there is a chance that Dorothy will
                be separated from Privaty (again) and replaced by either Rouge
                or D:Wife in the fabled Alice-SBS reload team. The idea of being
                able to deploy Blanc without Noir is also something that you may
                want to peer into.
              </p>
              <p>
                <strong>Note:</strong> Beware! Be careful when using your Gold
                Mileage. Nikke's 2nd Anniversary is very close, and we would
                recommend you save at least 200 Gold Mileage for the anniversary
                pilgrim unit to confirm your chances of getting her! With the
                two upcoming DPS units who look like great choices for Water
                Weak Raids — 'Quency: Escape Queen' and 'Phantom' — make sure
                you manage your resources well. Remember, while Rouge is a meta
                unit for Raids, she can be skipped if you lack funds. You can
                always wishlist her afterward and hopefully get her in the
                future. Don't go around mileaging her if you do not have enough
                funds for anniversary!
              </p>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-1">
              <SectionHeader title="Kit Analysis" />
              <StaticImage
                src="../../../images/nikke/reviews/rouge_guide_1.webp"
                alt="Kit"
              />
              <p>
                Rouge is a CDR extraordinaire. The instant 7-second CDR is
                something to thirst for. It unlocks a whole new world of
                possibilities in Raids, especially if you are a new player. Her
                other buffs are not grandiose, but we all already know that CDR
                alone is enough a reason to pull a unit. She can also 'heal' the
                team. Who doesn't want a unit that can provide CDR and improve
                the team's survivability at the same time? The fact that she
                also has tons of MAX HP buffs will also future-proof your roster
                should ShiftUp release more meta units that scale off MAX HP in
                the future.
              </p>
              <Alert variant="primary">
                <p>
                  Her 'healing' is not an actual recovery effect, so it will not
                  trigger Crown's S2 skill nor be affected by HP potency and
                  other kinds of stuff.
                </p>
              </Alert>
              <p>
                To elaborate, increasing MAX HP without restoring HP is a
                dual-edged sword. It is useless for most units that do not
                possess skills that scale off final MAX HP but useful for units
                that have one (such as other healers & 2B). At the same time, it
                is also a detriment for units who are reliant on being higher
                than a certain HP% threshold as increasing the HP cap without
                restoring HP will relatively lower the current HP%. Affected
                units include A2, Noir, and Alice (though Alice can heal herself
                back up quickly).
              </p>
              <Alert variant="primary">
                <p>
                  Rouge also belongs in the squad 777, so that means when she is
                  in the team, you can separate Blanc & Noir and run either of
                  them alone. While there is no reason to use Noir this way,
                  Blanc's benefit from having someone from the 777 squad in the
                  team is huge. And, since Noir is pretty much useless and often
                  considered a slot pressure, Rouge + Blanc is a reasonable team
                  composition from a theoretical perspective.
                </p>
              </Alert>
              <h5>Worht Drac</h5>
              <blockquote>
                <p>
                  ■ Activates when attacking with Full Charge for 8 time(s).
                  Affects all allies.
                </p>
                <p>
                  Max HP ▲ 5% of the caster's Max HP without restoring HP, lasts
                  for 5 sec.
                </p>
                <p>Cooldown of Burst Skill ▼ 7 sec.</p>
              </blockquote>
              <p>
                After sniping with a full charge 8 times, Rouge throws a card
                (presumably) and grants increased MAX HP to the team without
                restoring HP for 5s. This is pretty much negligible and pretty
                much ShiftUp's effort to make something there without actually
                being there. Besides, Rouge will take slightly over ~12s to
                full-charge 8 times, so that means the uptime for this buff is
                very low too. Now, you can say it helps buff 2B's damage
                slightly, but at the same time it also makes Alice, Noir, A2,
                and everyone else reliant on being higher than a certain %HP
                threshold slightly easier to lose their buffs.
              </p>
              <p>
                The only important bit from this skill is the CDR portion. It is
                what makes Rouge part of the meta. Instant 7s CDR, identical to
                D:Wife's. This allows your team to rotate smoothly ever since
                the second burst rotation; no more annoying hiccups. While this
                is not going to matter a lot for teams with low burst generation
                because you will spend a lot of time charging, units like Alice
                and RH can generate burst very quickly.
              </p>
              <Alert variant="primary">
                <p>
                  To achieve a smoother burst rotation, it is recommended (not
                  mandatory) to obtain at least 1× Max Ammo OL. This will make
                  her base ammo match the 8-ammo requirement from S1. Otherwise,
                  you may need Wingman Cube Level 7 to achieve the same effect.
                </p>
              </Alert>
              <h5>Pilf Nioc</h5>
              <blockquote>
                <p>
                  ■ Activates when assigned to the back row in battle. Affects
                  self and 2 allies on both sides.
                </p>
                <p>Sword Coin: Attack Damage ▲ 6.65% continuously.</p>
                <p>
                  ■ Activates when attacking with Full Charge for 30 time(s).
                  Affects self and 2 allies on both sides when in Sword Coin
                  status.
                </p>
                <p>Shield Coin: Damage Taken ▼ 15.2% continuously.</p>
                <p>
                  ■ Activates when using Burst skill for 5 time(s). Affects all
                  allies when in Shield Coin status.
                </p>
                <p>
                  Double Sword Coin: Max HP ▲ 15.08% of caster's Max HP
                  continuously.
                </p>
              </blockquote>
              <p>
                As long as Rouge is alive and performs certain actions, she will
                collect coins from time to time, providing the team with buffs.
                Coins also strengthen the effects of her Burst Skill, which we
                will cover in the next section. There are three coins that can
                be activated:
              </p>
              <ul>
                <li>
                  If Rouge is placed in the back row (see picture), in P2 or P4,
                  her Sword Coin will activate, providing self and the adjacent
                  front-row units a permanent medium ATK DMG buff.
                </li>
                <ul>
                  <li>
                    As usual, ATK DMG can be diluted or not depending on the
                    DPS. Undiluted kits will find this buff impactful, but the
                    same goes for diluted ones. The good thing is that it is
                    permanent, so it benefits both burst units and non-burst
                    units equally. She also needs to have Sword Coin before she
                    can collect other types of coins, hence placing her in the
                    back row is a must.{' '}
                  </li>
                </ul>
              </ul>
              <StaticImage
                src="../../../images/nikke/reviews/rouge_guide_2.webp"
                alt="Kit"
              />
              <ul>
                <li>
                  Once Rouge successfully performs 30 full-charge attacks, and
                  if Sword Coin is active when this is achieved, Shield Coin
                  will activate, greatly reducing the Damage Taken by self and
                  the DPS units buffed by Sword Coin.
                </li>
                <ul>
                  <li>
                    Damage Taken reduction is generally useful in Raids and even
                    a single percent can make a difference when running a
                    no-heal team. And, since Rouge reduces the Damage Taken of
                    the buffed DPS, any DPS with self-heal and a lot of ATK can
                    passively 'taunt' the boss and absorb damage for the team.
                    Since Shield Coin is permanent, and you want to trigger it
                    as early as possible, pay extra care when using her to
                    generate burst gen with shot spamming in the first few
                    rotations. After Shield Coin is enabled, you do not have to
                    worry about this anymore.
                  </li>
                </ul>
              </ul>
              <ul>
                <li>
                  Once Rouge uses Burst Skill 5 times, and if both Sword Coin
                  and Shield Coin are active when this is achieved, Double Sword
                  Coin will activate, 'healing' the team once as she increases
                  their MAX HP for the first time.{' '}
                </li>
                <ul>
                  <li>
                    This skill heals the team a small amount when it first
                    activates and increases their MAX HP permanently. The
                    healing may seem small, but as trivial as it may seem, it
                    can be just enough for your team to last a whole run. Shield
                    Coins also determines whether Rouge will heal your team with
                    her Burst Skill. Additionally, the MAX HP also synergizes
                    with units who scale off MAX HP like 2B. Since it scales off
                    caster's MAX HP, higher base stats and also Vigor Cube will
                    affect how much 'healing' your units receive.{' '}
                  </li>
                </ul>
              </ul>
              <Alert variant="primary">
                <p>
                  Note that the 'stacking' begins at the start of the battle.
                  Whether a new coin activates will depend on whether the other
                  prerequisite coins have already been activated when the
                  condition is satisfied. For example, before Rouge uses Burst
                  Skill 5 times, you want to make sure she has performed a
                  full-charge 30 times and activated her Shield Coin.
                </p>
              </Alert>
              <h5>Retsam Emag</h5>
              <blockquote>
                <p>■ Affects all allies.</p>
                <p>ATK ▲ 15.07% of caster's ATK for 10 sec.</p>
                <p>
                  ■ Activates when in Sword Coin status. Affects all allies.
                </p>
                <p>
                  Max HP ▲ 10.15% of caster's Max HP without restoring HP, lasts
                  for 10 sec.
                </p>
                <p>
                  ■ Activates when in Shield Coin status. Affects all allies.
                </p>
                <p>Max HP ▲ 20.1% of caster's Max HP, lasts for 10 sec.</p>
                <p>
                  ■ Activates when in Double Sword Coin status. Affects all
                  allies.
                </p>
                <p>
                  Max HP ▲ 30.02% of caster's Max HP without restoring HP, lasts
                  for 10 sec.
                </p>
              </blockquote>
              <p>
                Without any coins, Rouge will temporarily increase the ATK of
                the team based on her base ATK stat. The value is not that big,
                so it is not going to be mind-blowing, but it is there and can
                be felt, especially in High Deficit Campaign and with units that
                do not have a lot of ATK.
              </p>
              <p>
                Depending on the coins she has collected, additional effects
                will also trigger:
              </p>
              <ul>
                <li>
                  If she has a Sword Coin for being in the back row, she will
                  increase the team's MAX HP without restoring their HP as well.
                </li>
                <li>
                  If she has a Shield Coin for full-charging 30 times while
                  Sword Coin is active, then she can 'heal' the team as she
                  increases their MAX HP with every burst rotation. This is very
                  important and also the reason why you want to trigger Shield
                  Coin as early as possible for survivability reasons.
                </li>
                <li>
                  If she has a Double Sword Coin for having used Burst Skill for
                  a total of 5 times while both other coins are active, then the
                  effect of increasing the team's MAX HP without restoring HP in
                  her Burst Skill will become amplified.
                </li>
              </ul>
              <Alert variant="primary">
                <p>
                  Since the offensive effect of this skill is rather weak, which
                  is often the case unless you are pairing her with 2B, you can
                  use her as an off-burst CDR unit if minmaxing or slot pressure
                  is not a concern.
                </p>
              </Alert>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-2">
              <SectionHeader title="Usage Analysis and Tier List" />

              <h5>
                Campaign (LD) - <strong className="ss">SS</strong> | Campaign
                (HD) - <strong className="ss">SS</strong>
              </h5>
              <p>
                Similar performance to D:Wife and fulfills the same role, aka as
                an instant CDR unit, which is great for stages that require you
                to rotate fast. D:Wife technically has a stronger offense since
                her Pierce DMG buff is broken for Alice and RH. However, if you
                use neither or need a decent alternative that also 'heal' (does
                not trigger Crown's S2 effect), you can use Rouge. Rouge also
                allows you to use Blanc without Noir, which can be important for
                newer players who do not have access to a complete roster.
              </p>
              <p>
                In addition, Rouge is an excellent unit for Tetra Tribe Tower.
                Since Volume is useless, and Rouge offers better buffs (and can
                separate Blanc from Noir), Rouge is a direct vertical upgrade
                that everyone should consider getting.
              </p>
              <h5>
                Boss (Solo) - <strong className="ss">SS</strong> | Boss (Adds) -{' '}
                <strong className="ss">SS</strong>
              </h5>
              <p>
                Similar performance to D:Wife but with a different focus. While
                D:Wife's offensive buffs as stronger for Sniper Rifles with
                Pierce, Rouge is more of a generalist and can be paired with
                almost anyone. She fits well with units that benefit from Max HP
                buffs like 2B, but on the contrary also antagonizes those who
                have HP threshold mechanics, like A2 and Noir. Alice counts, but
                we believe the impact is not too drastic. Rouge also 'heals' the
                team and can separate Blanc from Noir (which also allows them to
                support units that despise Max Ammo).
              </p>
              <p>
                Since she is technically better than a plain Dorothy, Volume,
                and Dolla, she becomes the third best CDR unit in this game,
                making her a top choice for both Union and Solo Raid. She will
                open a lot of team-building opportunities to explore, which will
                allow you to perfect your current raid compositions even
                further.
              </p>
              <h5>
                PVP - <strong className="c">C</strong>
              </h5>
              <p>
                Most of Rouge's enhancing effects are locked behind virtual
                stacks that can only be gained after some time (around 1
                minute). The current PvP system does not allow units to ramp up
                this long.
              </p>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-3">
              <SectionHeader title="Investments" />
              <h5>Gear Investments</h5>
              <p>
                Rouge has a base ammo of 6. Without any Max Ammo OL, you are
                prone to stutters, where you have already recharged enough Burst
                Energy but have to wait Rouge to finish one or two more Full
                Charge(s) before you can continue cycling. This does not happen
                all the time, but it does, and you want to avoid it at all costs
                if you are extremely competitive in Solo Raid. Otherwise, the
                difference is not going to matter much.
              </p>
              <p>
                Overload and leveling gear also increase her base ATK stats,
                which strengthen her caster's ATK buff.
              </p>

              <ul>
                <li>
                  <strong>Essential:</strong> 1× Max Ammo
                </li>
                <li>
                  <strong>Ideal:</strong>
                </li>
                <li>
                  <strong>Passable:</strong>
                </li>
                <li>
                  <strong>Priority:</strong> Medium (PvE)
                </li>
              </ul>
              <h5>Skill Investments</h5>
              <ul>
                <li>
                  <strong>Skill 1: 4~10</strong>
                </li>
                <ul>
                  <li>
                    {' '}
                    Her S1 is her purpose. Higher levels mean higher CDR.
                    Technically, there is no breakpoint, but Level 4 gives you
                    5-second reduction, Level 6 gives you 6-second reduction,
                    and Level 10 gives you 7-second reduction. Slower teams may
                    not need much CDR, but faster teams like ones with RH/Alice
                    may need it.
                  </li>
                </ul>
                <li>
                  <strong>Skill 2: 4~10</strong>
                </li>
                <ul>
                  <li>
                    {' '}
                    Rouge's S2 provides a permanent ATK DMG buff and also
                    reduces Damage Taken. To save materials, it is fine to leave
                    this at 4, but you may want to up it to 7 and eventually 10
                    with overflowing resources. There is no breakpoint to worry
                    about, and it's all about more damage.
                  </li>
                </ul>
                <li>
                  <strong>Burst Skill: 4~7</strong>
                </li>
                <ul>
                  <li>
                    {' '}
                    A minor ATK buff but still an offensive buff. We recommend
                    upgrading this to only 4 or 7 since the value is not that
                    high. A different case would apply if you are pairing her
                    with units who scale tremendously with Max HP buffs, such as
                    2B, for which you may want to max this skill to 10. Higher
                    levels also mean more heal per rotation.
                  </li>
                </ul>
              </ul>
              <h5>Suggested Progression Path</h5>
              <div className="nikke-skills-tips">
                <div className="nikke-skills-row header">
                  <div className="column character">&nbsp;</div>
                  <div className="column suggestions pve">PVE Investment</div>
                  <div className="column suggestions pvp">PVP Investment</div>
                  <div className="column priority">Priority</div>
                </div>
                <div className="nikke-skills-row data">
                  <NikkeSkill char="rouge" />
                </div>
              </div>
              <br />
              <h5>Cube Investments</h5>
              <StaticImage
                src="../../../images/nikke/generic/cube_3_sm.png"
                alt="Guide"
              />
              <ul>
                <li>
                  Resilience Cube is the ideal cube for Rouge if she has 1× Max
                  Ammo OL. This will make the triggering of her CDR much
                  smoother.
                </li>
                <li>
                  Otherwise, without Max Ammo OL, Wingman Cube Level 7+ is the
                  next best alternative to get her Ammo to 8 to match the number
                  requirement of Skill 1.
                </li>
                <li>
                  Alternatively, if more survivability is desired, Vigor Cube is
                  a great option. It increases her Max HP, which increases how
                  much MAX HP (and in turn 'healing') is received by the team.{' '}
                </li>
              </ul>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-4">
              <SectionHeader title="PvE Team Compositions" />
              <h5>Team #1: Anis Holds a Summer Party</h5>
              <div className="nikke-team-showcase">
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="blanc" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="alice" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="sparkling-summer-anis"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="rouge" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="ludmilla-winter-owner"
                      enablePopover
                    />
                  </div>
                </div>
              </div>
              <p>
                Tetra Tower Squad. With the removal of Noir, S. Anis can finally
                enjoy Blanc's buffs without bricking herself. With Rouge, she
                can be added to your Tetra Tower squad without any loss of DPS
                and can effectively be used as a B3.
              </p>
              <h5>Team #2: Breaking the Bunny Duo</h5>
              <div className="nikke-team-showcase">
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="placeholder-box">B3</div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="rouge" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="placeholder-box">B3</div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="blanc" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-flex">Burst Flex</h5>
                  <div className="placeholder-box">Flex</div>
                </div>
              </div>
              <p>
                Challenges the meta by removing Noir out of rotation (or moving
                her to a different team). The goal here is to not use Noir,
                which is considered a slot-pressure unit in most cases nowadays,
                and include another B3 DPS or a more effective support for the
                team. Examples of 3 DPS combos include: Asuka + Rei + Modernia
                for Fire, Ein + S.Anis + Scarlet/ExiaTreasure for Electric, and
                RedHood + Maxwell/Laplace with Treasure/SnowWhite + Summer Helm
                for Iron.
              </p>
              <h5>Team #3: Rouge Enters the Kingdom</h5>
              <div className="nikke-team-showcase">
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="mari-makinami-illustrious"
                      enablePopover
                    />
                    <NikkeCharacter mode="icon" slug="crown" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="placeholder-box">B3</div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="placeholder-box">B3</div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="rouge" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-flex">Burst Flex</h5>
                  <div className="placeholder-box">Flex</div>
                </div>
              </div>
              <p>
                If you lack D:KW and Liter, Rouge can help you a lot in
                Campaign. Her CDR is great, she provides heals, and simply is a
                better choice when compared to other CDR units like Volume or
                Dolla. This team can also house the famous Electric comp: ExiaTr
                Crown Ein S.Anis Rouge, where Rouge can fit in effectively as an
                off-burst CDR unit. This is because Rouge's Burst Skill effects
                are not that strong to matter anyways, not when compared to
                ExiaTr's.
              </p>
              <p>
                Rouge can also be used with Mari, Summer Rosanna, and other B2
                units like how you would use normal CDR units.
              </p>
              <h5>Team #4: Flashbacks to Old Collaboration</h5>
              <div className="nikke-team-showcase">
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="2b" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="rouge" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="placeholder-box">B3</div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="blanc" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-flex">Burst Flex</h5>
                  <div className="placeholder-box">Flex</div>
                </div>
              </div>
              <p>
                There is only one DPS unit who scales her damage output with MAX
                HP buffs: 2B. Well, Kilo counts, but she is not really a DPS. In
                this team, we aim so that 2B 'taunts' the enemy and takes damage
                due to having the highest ATK. Then, Blanc's Burst Skill will
                heal her and also increase her MAX HP, further strengthening her
                damage output.
              </p>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-5">
              <SectionHeader title="Pros & Cons" />
              <Row className="pros-cons">
                <Col sm="12" md="6">
                  <div className="box pros">
                    <h5>Pros</h5>
                    <hr />
                    <div className="raw list">
                      <ul>
                        <li>Her CDR is valued everywhere (except PvP).</li>
                        <li>Provides ATK and permanent ATK DMG buffs.</li>
                        <li>
                          Synergizes with Blanc & Noir, allowing them to be
                          separated from each other.
                        </li>
                        <li>First CDR unit that can also 'heal' the team.</li>
                        <li>Provides Damage Taken reduction for her team</li>
                        <li>
                          Provides CDR with no ramp-up time, unlike Liter,
                          allowing for faster burst chains in Campaign.
                        </li>
                        <li>
                          Provides MAX HP buffs, which synergize well with 2B
                          and other healers.
                        </li>
                        <li>
                          Helps us identify people with reading issue (those who
                          type "Rogue" instead of "Rouge").
                        </li>
                      </ul>
                    </div>
                  </div>
                </Col>
                <Col sm="12" md="6">
                  <div className="box cons">
                    <h5>Cons</h5>
                    <hr />
                    <div className="raw list">
                      <ul>
                        <li>
                          Forced back-row placement might be non-optimal for
                          auto.
                        </li>
                        <li>
                          ATK DMG and Damage Taken are only for self + 2 units.
                        </li>
                        <li>
                          Some buffs, such as damage reduction, take a lot of
                          time to activate, reducing their impact in Campaign &
                          PvP severely.
                        </li>
                        <li>
                          Feels lackluster in offensive buffs when compared to
                          Liter & D:Wife.
                        </li>
                        <li>
                          Needs some investments on S1 to be fully optimal CDR
                          wise.
                        </li>
                        <li>
                          Caster's ATK buff, so may need OL for extra base stats
                          ideally, but OL lines do not affect her ATK buff.
                        </li>
                        <li>
                          Needs 1 Max Ammo from OL gear to have smooth S1
                          triggers.{' '}
                        </li>
                        <li>
                          Provides Max HP without 'heal', which can be
                          anti-synergistic with units that require a certain HP%
                          threshold, like A2 & Noir.
                        </li>
                        <li>
                          People don't like it when we laugh at them when their
                          autocorrect turns Rouge into Rogue.
                        </li>
                      </ul>
                    </div>
                  </div>
                </Col>
              </Row>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
          </div>
          <div className="right-sidebar with-sticky">
            <ScrollspyNav
              scrollTargetIds={[
                'section-0',
                'section-1',
                'section-2',
                'section-3',
                'section-4',
                'section-5'
              ]}
              activeNavClass="is-active"
              scrollDuration="500"
              headerBackground="true"
              offset={100}
            >
              <h6>Jump to</h6>
              <ul className="list-unstyled">
                <li>
                  <a href="#section-0">Introduction</a>
                </li>
                <li>
                  <a href="#section-1">Kit Analysis</a>
                </li>
                <li>
                  <a href="#section-2">Tier List</a>
                </li>
                <li>
                  <a href="#section-3">Investments</a>
                </li>
                <li>
                  <a href="#section-4">PVE Team Compositions</a>
                </li>
                <li>
                  <a href="#section-5">Pros & Cons</a>
                </li>
              </ul>
            </ScrollspyNav>
            <div className="fuse-ad-placeholder" data-fuse="22844297250"></div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default NikkeGuidesRouge;

export const Head: React.FC = () => (
  <Seo
    title="Rouge Guide & Review | NIKKE | Prydwen Institute"
    description="A guide & review for Rouge in NIKKE: Goddess of Victory."
    game="nikke"
  />
);
